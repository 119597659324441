import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
    DialogContent,
    Grid,
    Typography,
    Box,
    TextField,
    Button,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import { AddRounded, DragIndicatorRounded, DeleteForeverRounded } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import Loader from '../../../../../../Helper/Loader';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // change background colour if dragging
    border: "1px solid transparent",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder="0"
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));

const DiscountFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    %
                </Box>
            </InputAdornment>
        }}

        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={1}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={props.inputProps}
        placeholder={props.placeholder}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Content({ productPrices, onChange }) {
    const dispatch = useDispatch();
    let { id } = useParams();
    const {customer_groups, pricesErrors, product} = useSelector(state => ({
        ...state.product
    }), shallowEqual);

    const [typeOptions] = useState([
        {
            id: 1,
            title: 'Fixed',
            value: 'fixed'
        },
        {
            id: 2,
            title: 'Percent',
            value: 'percent'
        }
    ]);

    useEffect(() => {
        dispatch(productActions.onLoadProductPrices(id));
    }, []);

    useEffect(() => {
        if (customer_groups) {
            var pricesAttributes;
            if (product.product_prices.filter(el => {return !el._destroy}).length === 0) {
                const group = customer_groups.find(customer_group => customer_group.group === 'all_groups');
                if (product.product_prices.length === 0) {
                    pricesAttributes = [{ customer_group_id: group._id, sort_order: 0, quantity: 1, price_type: 'fixed', amount: ''}];
                } else {
                    pricesAttributes = [...product.product_prices, { customer_group_id: group._id, sort_order: 0, quantity: 1, price_type: 'fixed', amount: ''}];
                }
            } else {
                pricesAttributes = product.product_prices;
            }
            onChange(pricesAttributes)
        }
        
    }, [customer_groups]);

    function onAddPriced() {
        const group = customer_groups.find(customer_group => customer_group.group === 'all_groups');
        const newPrices = [...productPrices, { customer_group_id: group._id, sort_order: '', quantity: 1, price_type: 'fixed', amount: ''}]
        newPrices.map((price, i)=> {
            const updateitem = { ...price, sort_order: i}
            return newPrices.splice(i, 1, updateitem)
        })
        onChange(newPrices)
    }

    function onPricesChange(index, event) {
        const key = event.target.name;
        const value = event.target.value;
        const newPrices = [...productPrices];
        newPrices.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            const updateitem = { ...item, [key]: value}
            return newPrices.splice(index, 1, updateitem)  
        })
        onChange(newPrices);
    }

    function onRemovePrices(index) { 
        const newPrices = [...productPrices];
        newPrices.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            if (item._id) {
                const updateitem = { ...item, _destroy: true}
                return newPrices.splice(index, 1, updateitem)
            } else {
                return newPrices.splice(index, 1)
            }
        })
        

        newPrices.map((price, i)=> {
            const updateitem = { ...price, sort_order: i}
            return newPrices.splice(i, 1, updateitem)
        })
        onChange(newPrices)
    }

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
        return;
        }

        const pricesReorder = reorder(
        productPrices,
            result.source.index,
            result.destination.index
        );
        
        const newPrices = pricesReorder.map((price, index)=> {
            return {...price, sort_order: index}
        })
        onChange(newPrices);       
    }

    function error(name) {
        if (pricesErrors != null && pricesErrors[name] != null) {
            return pricesErrors[name][0]
        }

        return null;
    }


    if (!customer_groups || productPrices.length === 0) {
        return (
            <Box sx={{minHeight: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Loader />
            </Box>
        )
    }

    function priceAmount(price, index) {
        if (price.price_type === 'fixed') {
            return (
                <CurrencyFormat
                    error={!!error(`product_prices[${index}].amount`)}
                    id="amount"
                    name="amount"
                    placeholder="0"
                    value={price.amount}
                    helperText={error(`product_prices[${index}].amount`)}
                    variant="outlined"
                    onChange={onPricesChange.bind(this, index)}
                />
            )
        } else {
            return (
                <DiscountFormat
                    error={!!error(`product_prices[${index}].amount`)}
                    id="amount"
                    name="amount"
                    placeholder="0"
                    value={price.amount}
                    inputProps={{
                        startAdornment: <InputAdornment position="start">
                            <Box fontWeight={700}>
                                %
                            </Box>
                        </InputAdornment>,
                    }}
                    helperText={error(`product_prices[${index}].amount`)}
                    variant="outlined"
                    onChange={onPricesChange.bind(this, index)}
                />
            )
        }
    }
    return (
        <PricingContent>
            <Grid container direction="column" spacing={3} alignItems="flex-end">
                <Grid item>
                    <Typography variant="h6" fontWeight={700}>
                        Harga Pelanggan Khusus
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Masukan potongan harga khusus untuk grup pelanggan tertentu, potongan dapat dengan batas minimal kuantitas, potongan langsung atau dengan diskon persentase (%).
                    </Typography>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container spacing={3}>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography lineheight={3} component="div">
                                <Box fontWeight={700}>
                                    Grup Pelanggan
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography lineheight={3} component="div">
                                <Box fontWeight={700}>
                                    Kuantitas
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography lineheight={3} component="div">
                                <Box fontWeight={700}>
                                    Potongan
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                                droppableId="all-droppables"
                                type="config"
                                >
                                {provided => (
                                    <Box
                                    className="columns"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    >
                                    {
                                        productPrices.sort((a, b) => a.sort_order - b.sort_order).map((price, index) => {
                                            if (price._destroy) {
                                                return null;
                                            }
                                            return (
                                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Box
                                                        sx={{marginBottom: '15px !important'}}
                                                        {...provided.draggableProps}
                                                        ref={provided.innerRef}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        >
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                            <IconButton sx={{marginTop: '8px'}} color="primary" {...provided.dragHandleProps}>
                                                                <DragIndicatorRounded />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl 
                                                                fullWidth
                                                                variant="outlined">
                                                                <Select
                                                                    name="customer_group_id"
                                                                    value={price.customer_group_id}
                                                                    onChange={onPricesChange.bind(this, index)}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                                    <MenuItem value="" disabled>
                                                                        Pilih Tipe
                                                                    </MenuItem>
                                                                    {
                                                                        customer_groups.map(option => {
                                                                            return(
                                                                                <MenuItem key={option._id} value={option._id}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <QuantityFormat
                                                                error={!!error(`product_prices[${index}].quantity`)}
                                                                name="quantity"
                                                                placeholder="0"
                                                                value={price.quantity}
                                                                helperText={error(`product_prices[${index}].quantity`)}
                                                                variant="outlined"
                                                                onChange={onPricesChange.bind(this, index)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl 
                                                                fullWidth
                                                                variant="outlined">
                                                                <Select
                                                                    name="price_type"
                                                                    value={price.price_type}
                                                                    onChange={onPricesChange.bind(this, index)}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                                    <MenuItem value="" disabled>
                                                                        Pilih Tipe
                                                                    </MenuItem>
                                                                    {
                                                                        typeOptions.map(option => {
                                                                            return(
                                                                                <MenuItem key={option.value} value={option.value}>
                                                                                    {option.title}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            {priceAmount(price, index)}
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <IconButton sx={{marginTop: '8px'}}  aria-label="delete" onClick={onRemovePrices.bind(this, index)}>
                                                                <DeleteForeverRounded />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                    </Box>
                                                )}
                                                </Draggable>
                                            )
                                        }
                                        
                                        )
                                    }
                                    {provided.placeholder}
                                    </Box>
                                )}
                                </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>
                <Grid item>
                    <AddButton 
                        variant="contained"
                        size="large"
                        disableElevation
                        startIcon={<AddRounded />}
                        onClick={onAddPriced}
                        >
                        Tambah Grup Pelanggan
                    </AddButton>
                </Grid>
            </Grid>
        </PricingContent>
    )
}


const PricingContent = styled(DialogContent)(({theme}) => ({
    padding: '45px !important',
    minHeight: 400
}));

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: 20
}));
