import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as commonActions from './actions/commonActions';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import {
  Box,
  Snackbar,
  Alert,
  Slide
  } from '@mui/material';
import About from './components/Frontend/About';
import Home from './components/Frontend/Home';
import Privacy from './components/Frontend/Privacy';
import ResetPassword from './components/Frontend/ResetPassword';
import SignIn from './components/Frontend/SignIn';
import SignUp from './components/Frontend/SignUp';
import Term from './components/Frontend/Term';
import NotFound from './components/NotFound';
import Backend from './components/Backend';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import '../src/assets/stylesheets/Style.css';
import Setting from './components/Backend/Accounts/Settings';
import Adjustment from './components/Backend/Stores/Inventories/Adjustment';
import AdjustmentNew from './components/Backend/Stores/Inventories/Adjustment/New';
import AdjustmentEdit from './components/Backend/Stores/Inventories/Adjustment/Edit';
import Dashboard from './components/Backend/Dashboard';
import Category from './components/Backend/Products/Category';
import CategoryNew from './components/Backend/Products/Category/New';
import CategoryEdit from './components/Backend/Products/Category/Edit';
import CustomerDisplay from './components/Backend/Marketings/Contents/CustomerDisplay';
import CustomerDisplayNew from './components/Backend/Marketings/Contents/CustomerDisplay/New';
import CustomerDisplayEdit from './components/Backend/Marketings/Contents/CustomerDisplay/Edit';
import Customer from './components/Backend/Customers/Customer';
import CustomerShow from './components/Backend/Customers/Customer/Show';
import CustomerGroup from './components/Backend/Customers/CustomerGroup';
import CustomerGroupNew from './components/Backend/Customers/CustomerGroup/New';
import CustomerGroupEdit from './components/Backend/Customers/CustomerGroup/Edit';
import Inventory from './components/Backend/Stores/Inventories/Inventory';
import Employee from './components/Backend/Organizations/Employees/Employee';
import EmployeeNew from './components/Backend/Organizations/Employees/Employee/New';
import EmployeeShow from './components/Backend/Organizations/Employees/Employee/Show';
import Organization from './components/Backend/Organizations/Others/Organization';
import OrganizationEdit from './components/Backend/Organizations/Others/Organization/Edit';
import OrganizationNew from './components/Backend/Organizations/Others/Organization/New';
import JobPosition from './components/Backend/Organizations/Others/JobPosition';
import JobPositionEdit from './components/Backend/Organizations/Others/JobPosition/Edit';
import JobPositionNew from './components/Backend/Organizations/Others/JobPosition/New';
import Product from './components/Backend/Products/Product';
import ProductNew from './components/Backend/Products/Product/New';
import ProductEdit from './components/Backend/Products/Product/Edit';
import Order from './components/Backend/Stores/Histories/Order';
import OrderShow from './components/Backend/Stores/Histories/Order/Show';
import OrderCanceled from './components/Backend/Stores/Histories/OrderCanceled';
import OrderCanceledShow from './components/Backend/Stores/Histories/OrderCanceled/Show';
import OrderDelivery from './components/Backend/Stores/Histories/OrderDelivery';
import OrderDeliveryShow from './components/Backend/Stores/Histories/OrderDelivery/Show';
import OrderDeliveryReturn from './components/Backend/Stores/Histories/OrderDeliveryReturn';
import OrderDeliveryReturnShow from './components/Backend/Stores/Histories/OrderDeliveryReturn/Show';
import PurchaseOrder from './components/Backend/Stores/Inventories/PurchaseOrder';
import PurchaseOrderNew from './components/Backend/Stores/Inventories/PurchaseOrder/New';
import PurchaseOrderEdit from './components/Backend/Stores/Inventories/PurchaseOrder/Edit';
import PurchaseOrderReturn from './components/Backend/Stores/Inventories/PurchaseOrderReturn';
import PurchaseOrderReturnNew from './components/Backend/Stores/Inventories/PurchaseOrderReturn/New';
import PurchaseOrderReturnEdit from './components/Backend/Stores/Inventories/PurchaseOrderReturn/Edit';
import Store from './components/Backend/Stores/Outlets/Store';
import StoreNew from './components/Backend/Stores/Outlets/Store/New';
import StoreEdit from './components/Backend/Stores/Outlets/Store/Edit';
import StoreShow from './components/Backend/Stores/Outlets/Store/Show';
import Supplier from './components/Backend/Stores/Suppliers/Supplier';
import SupplierNew from './components/Backend/Stores/Suppliers/Supplier/New';
import SupplierEdit from './components/Backend/Stores/Suppliers/Supplier/Edit';
import SupplierShow from './components/Backend/Stores/Suppliers/Supplier/Show';
import Transfer from './components/Backend/Stores/Inventories/Transfer';
import TransferNew from './components/Backend/Stores/Inventories/Transfer/New';
import TransferEdit from './components/Backend/Stores/Inventories/Transfer/Edit';
import EmployeeAccess from './components/Backend/Organizations/EmployeeAccess';
import EmployeeAccessNew from './components/Backend/Organizations/EmployeeAccess/New';
import EmployeeAccessEdit from './components/Backend/Organizations/EmployeeAccess/Edit';
import StorePosShift from './components/Backend/Stores/Histories/StorePosShift';
import StorePosShiftShow from './components/Backend/Stores/Histories/StorePosShift/Show';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return null;
}

function Notice() {
  const dispatch = useDispatch();
  const { notice, onNotice } = useSelector(state => ({
      ...state.common
  }), shallowEqual);


  const [state, setState] = useState({
    Transition: Slide,
  });

  useEffect(() => {
    if (onNotice) {
        setState({
            Transition: SlideTransition,
        });
    }  
  },[onNotice]);

  function onClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(commonActions.onHideNotice());
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }
  if (!notice) {
    return null;
  }
  return (
    <Snackbar
        open={onNotice}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={onClose}
        autoHideDuration={3500}
        disableWindowBlurListener
        TransitionComponent={state.Transition}
        key={state.Transition.name}>
        <Alert severity={notice.type ? notice.type : "error"}>{notice.text}</Alert>
    </Snackbar>
  )
}

function HomeRoute() {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectTo } = useSelector(state => ({
      ...state.common
  }), shallowEqual);

  useEffect(() => {
    if (redirectTo) {
        navigate(redirectTo);
        dispatch(commonActions.onRedirect());
    }
  },[redirectTo, navigate, dispatch]);

  if (window.localStorage.getItem('userToken')) {
    return <Navigate to="/dashboard" state={{ from: location }} replace/>;
  }

  return <Outlet />;
}

function PrivateRoute() {
  let location = useLocation();
 const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectTo } = useSelector(state => ({
      ...state.common
  }), shallowEqual);

  useEffect(() => {
    if (redirectTo) {
        navigate(redirectTo);
        dispatch(commonActions.onRedirect());
    }
  },[redirectTo, navigate, dispatch]);

  if (!window.localStorage.getItem('userToken')) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return <Backend />;
}

const PermissionRoute = ({ children, requiredPermissions }) => {
  const { current_user } = useSelector(state => ({
      ...state.common
  }), shallowEqual);

  const { permissions, type } = current_user;
  const hasPermission = requiredPermissions.some(permission => permissions.includes(permission));

  if (!hasPermission && !(type === "user")) {
    return <Navigate to="/" />;
  }
  return children;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>  
          <Router>
            <ScrollToTop />
            <Notice />
            <Routes>
              <Route path="/" element={<HomeRoute />}>
                <Route index element={<Home />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="about" element={<About />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="term" element={<Term />} />
              </Route>
              
              <Route element={<PrivateRoute />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="accounts/settings" element={<PermissionRoute requiredPermissions={['edit_cashier_configuration', 'edit_invoice_configuration', 'edit_job_position_configuration']}><Setting /></PermissionRoute>} />
                <Route path="store-adjustments" element={<PermissionRoute requiredPermissions={['requested_store_adjustment','checked_store_adjustment', 'canceled_store_adjustment']}><Adjustment /></PermissionRoute>} />
                <Route path="store-adjustments/new" element={<PermissionRoute requiredPermissions={['requested_store_adjustment']}><AdjustmentNew /></PermissionRoute>} />
                <Route path="store-adjustments/:id/edit" element={<PermissionRoute requiredPermissions={['checked_store_adjustment', 'canceled_store_adjustment']}><AdjustmentEdit /></PermissionRoute>} />
                <Route path="categories" element={<PermissionRoute requiredPermissions={['create_category_product', 'edit_category_product']}><Category /></PermissionRoute>} />
                <Route path="categories/new" element={<PermissionRoute requiredPermissions={['create_category_product']}><CategoryNew /></PermissionRoute>} />
                <Route path="categories/new/:parent_id" element={<PermissionRoute requiredPermissions={['create_category_product']}><CategoryNew /></PermissionRoute>} />
                <Route path="categories/:id/edit" element={<PermissionRoute requiredPermissions={['edit_category_product']}><CategoryEdit /></PermissionRoute>} />
                <Route path="customer-displays" element={<PermissionRoute requiredPermissions={['create_customer_display', 'edit_customer_display']}><CustomerDisplay /></PermissionRoute>} />
                <Route path="customer-displays/new" element={<PermissionRoute requiredPermissions={['create_customer_display']}><CustomerDisplayNew /></PermissionRoute>} />
                <Route path="customer-displays/:id/edit" element={<PermissionRoute requiredPermissions={['edit_customer_display']}><CustomerDisplayEdit /></PermissionRoute>} />
                <Route path="customers" element={<PermissionRoute requiredPermissions={['view_customer', 'edit_customer']}><Customer /></PermissionRoute>} />
                <Route path="customers/:id" element={<PermissionRoute requiredPermissions={['view_customer', 'edit_customer']}><CustomerShow /></PermissionRoute>} />
                <Route path="customer-groups" element={<PermissionRoute requiredPermissions={['create_customer_group', 'edit_customer_group']}><CustomerGroup /></PermissionRoute>} />
                <Route path="customer-groups/new" element={<PermissionRoute requiredPermissions={['create_customer_group']}><CustomerGroupNew /></PermissionRoute>} />
                <Route path="customer-groups/:id/edit" element={<PermissionRoute requiredPermissions={['edit_customer_group']}><CustomerGroupEdit /></PermissionRoute>} />
                <Route path="inventories" element={<PermissionRoute requiredPermissions={['view_inventory']}><Inventory /></PermissionRoute>} />
                <Route path="employees" element={<PermissionRoute requiredPermissions={['view_employee', 'create_employee', 'edit_employee']}><Employee /></PermissionRoute>} />
                <Route path="employees/new" element={<PermissionRoute requiredPermissions={['create_employee']}><EmployeeNew /></PermissionRoute>} /> 
                <Route path="employees/:id" element={<PermissionRoute requiredPermissions={['view_employee']}><EmployeeShow /></PermissionRoute>} />
                <Route path="roles" element={<PermissionRoute requiredPermissions={['view_role', 'create_role', 'edit_role']}><EmployeeAccess /></PermissionRoute>} />
                <Route path="roles/new" element={<PermissionRoute requiredPermissions={['create_role']}><EmployeeAccessNew /></PermissionRoute>} /> 
                <Route path="roles/:id/edit" element={<PermissionRoute requiredPermissions={['edit_role']}><EmployeeAccessEdit /></PermissionRoute>} />
                <Route path="organizations" element={<PermissionRoute requiredPermissions={['view_organization', 'create_organization', 'edit_organization']}><Organization /></PermissionRoute>} />
                <Route path="organizations/new/:parent_id" element={<PermissionRoute requiredPermissions={['create_organization']}><OrganizationNew /></PermissionRoute>} />
                <Route path="organizations/:id/edit" element={<PermissionRoute requiredPermissions={['edit_organization']}><OrganizationEdit /></PermissionRoute>} />
                <Route path="job-positions" element={<PermissionRoute requiredPermissions={['view_job_position', 'create_job_position', 'edit_job_position']}><JobPosition /></PermissionRoute>} />
                <Route path="job-positions/new/:parent_id" element={<PermissionRoute requiredPermissions={['create_job_position']}><JobPositionNew /></PermissionRoute>} />
                <Route path="job-positions/:id/edit" element={<PermissionRoute requiredPermissions={['edit_job_position']}><JobPositionEdit /></PermissionRoute>} />
                <Route path="/products" element={<PermissionRoute requiredPermissions={["create_product", "edit_product"]}><Product /></PermissionRoute>} />
                <Route path="/products/new" element={<PermissionRoute requiredPermissions={["create_product"]}><ProductNew /></PermissionRoute>} />
                <Route path="/products/:id/edit" element={<PermissionRoute requiredPermissions={["edit_product"]}><ProductEdit /></PermissionRoute>} />
                <Route path="orders" element={<PermissionRoute requiredPermissions={['edit_order']}><Order /></PermissionRoute>} /> 
                <Route path="orders/:id" element={<PermissionRoute requiredPermissions={['edit_order']}><OrderShow /></PermissionRoute>} />
                <Route path="order-canceleds" element={<PermissionRoute requiredPermissions={['completed_order_canceled', 'canceled_order_canceled']}><OrderCanceled /></PermissionRoute>} /> 
                <Route path="order-canceleds/:id" element={<PermissionRoute requiredPermissions={['completed_order_canceled', 'canceled_order_canceled']}><OrderCanceledShow /></PermissionRoute>} />
                <Route path="order-deliveries" element={<PermissionRoute requiredPermissions={['completed_order_delivery', 'canceled_order_delivery']}><OrderDelivery /></PermissionRoute>} /> 
                <Route path="order-deliveries/:id" element={<PermissionRoute requiredPermissions={['completed_order_delivery', 'canceled_order_delivery']}><OrderDeliveryShow /></PermissionRoute>} />
                <Route path="order-delivery-returns" element={<PermissionRoute requiredPermissions={['completed_order_delivery_return', 'canceled_order_delivery_return']}><OrderDeliveryReturn /></PermissionRoute>} /> 
                <Route path="order-delivery-returns/:id" element={<PermissionRoute requiredPermissions={['completed_order_delivery_return', 'canceled_order_delivery_return']}><OrderDeliveryReturnShow /></PermissionRoute>} />
                <Route path="purchase-orders" element={<PermissionRoute requiredPermissions={[ 'requested_purchase_order', 'ordered_purchase_order', 'received_purchase_order', 'canceled_purchase_order']}><PurchaseOrder /></PermissionRoute>} />
                <Route path="purchase-orders/new" element={<PermissionRoute requiredPermissions={[ 'requested_purchase_order']}><PurchaseOrderNew /></PermissionRoute>} />
                <Route path="purchase-orders/:id/edit" element={<PermissionRoute requiredPermissions={['ordered_purchase_order', 'received_purchase_order', 'canceled_purchase_order']}><PurchaseOrderEdit /></PermissionRoute>} />
                <Route path="purchase-order-returns" element={<PermissionRoute requiredPermissions={['requested_purchase_order_return', 'approved_purchase_order_return', 'returned_purchase_order_return', 'canceled_purchase_order_return']}><PurchaseOrderReturn /></PermissionRoute>} />
                <Route path="purchase-order-returns/new" element={<PermissionRoute requiredPermissions={['requested_purchase_order_return']}><PurchaseOrderReturnNew /></PermissionRoute>} />
                <Route path="purchase-order-returns/:id/edit" element={<PermissionRoute requiredPermissions={['approved_purchase_order_return', 'returned_purchase_order_return', 'canceled_purchase_order_return']}><PurchaseOrderReturnEdit /></PermissionRoute>} />
                <Route path="stores" element={<PermissionRoute requiredPermissions={['view_store', 'create_store', 'edit_store']}><Store /></PermissionRoute>} />
                <Route path="stores/new" element={<PermissionRoute requiredPermissions={['create_store']}><StoreNew /></PermissionRoute>} />
                <Route path="stores/:id/edit" element={<PermissionRoute requiredPermissions={['edit_store']}><StoreEdit /></PermissionRoute>} />
                <Route path="stores/:id" element={<PermissionRoute requiredPermissions={['view_store']}><StoreShow /></PermissionRoute>} />
                <Route path="suppliers" element={<PermissionRoute requiredPermissions={['view_supplier', 'create_supplier', 'edit_supplier']}><Supplier /></PermissionRoute>} />
                <Route path="suppliers/new" element={<PermissionRoute requiredPermissions={['create_supplier']}><SupplierNew /></PermissionRoute>} />
                <Route path="suppliers/:id/edit" element={<PermissionRoute requiredPermissions={['edit_supplier']}><SupplierEdit /></PermissionRoute>} />
                <Route path="suppliers/:id" element={<PermissionRoute requiredPermissions={['view_supplier']}><SupplierShow /></PermissionRoute>} />
                <Route path="store-pos-shifts" element={<PermissionRoute requiredPermissions={['view_store_pos_shift', 'edit_store_pos_shift']}><StorePosShift /></PermissionRoute>} />
                <Route path="store-pos-shifts/:id" element={<PermissionRoute requiredPermissions={['view_store_pos_shift', 'edit_store_pos_shift']}><StorePosShiftShow /></PermissionRoute>} />
                <Route path="store-transfers" element={<PermissionRoute requiredPermissions={['requested_store_transfer', 'approved_store_transfer', 'transfered_store_transfer', 'canceled_store_transfer']}><Transfer /></PermissionRoute>} />
                <Route path="store-transfers/new" element={<PermissionRoute requiredPermissions={['requested_store_transfer']}><TransferNew /></PermissionRoute>} />
                <Route path="store-transfers/:id/edit" element={<PermissionRoute requiredPermissions={['approved_store_transfer', 'transfered_store_transfer', 'canceled_store_transfer']}><TransferEdit /></PermissionRoute>} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
