import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes';
import { getDepth } from './utils/tree-data-utils';
import { Paper,
    Grid,
    Button,
} from '@mui/material';
import {AddRounded, RemoveRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";


const style = {
    backgroundColor: 'white',
    cursor: 'move',
};
export default function Node(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { 
        name,
        listIndex, 
        node, 
        path,
        treeIndex, 
        depth,
        startDrag,
        dragHover,
        onDrop,
        maxDepth,
        toggleChildrenVisibility } = props;
    const ref = useRef(null);

    function getTargetDepth(dropTargetProps, monitor) {
        let dropTargetDepth = 0;
    
        const rowAbove = dropTargetProps.getPrevRow();
        if (rowAbove) {
          let { path } = rowAbove;
          // Limit the length of the path to the deepest possible
          dropTargetDepth = Math.min(path.length, dropTargetProps.path.length);
        }

        
    
        let blocksOffset;
        let dragSourceInitialDepth = (monitor.getItem().path || []).length;
    
        // handle row direction support
        const direction = 1;

        blocksOffset = Math.round(
        (direction * monitor.getDifferenceFromInitialOffset().x) /
            dropTargetProps.scaffoldBlockPxWidth
        );

        let targetDepth = Math.min(
          dropTargetDepth,
          Math.max(0, dragSourceInitialDepth + blocksOffset - 1)
        );
    
       
        // If a maxDepth is defined, constrain the target depth
        if (typeof maxDepth !== 'undefined' && maxDepth !== null) {
          const draggedNode = monitor.getItem().node;
          const draggedChildDepth = getDepth(draggedNode);
    
          targetDepth = Math.max(
            0,
            Math.min(targetDepth, maxDepth - draggedChildDepth - 1)
          );
        }
    
        return targetDepth;
    }

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CATEGORY,
        drop: (item, monitor) => {
            const result = {
                node: monitor.getItem().node,
                path: monitor.getItem().path,
                treeIndex: monitor.getItem().treeIndex,
                // treeId: this.treeId,
                minimumTreeIndex: props.treeIndex,
                depth: getTargetDepth(props, monitor),
              };
              onDrop(result);
      
              return result;
        },
        
        canDrop: (item, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                const rowAbove = props.getPrevRow();
                const abovePath = rowAbove ? rowAbove.path : [];
                const aboveNode = rowAbove ? rowAbove.node : {};
                const targetDepth = getTargetDepth(props, monitor);

                // Cannot drop if we're adding to the children of the row above and
                //  the row above is a function
                if (
                  targetDepth >= abovePath.length &&
                  typeof aboveNode.children === 'function'
                ) {
                  return false;
                }
            
                return true;
            }
            
    
            return false;
        },
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const targetDepth = getTargetDepth(
                props,
                monitor
              );
            const draggedNode = item.node;
            const needsRedraw =
            // Redraw if hovered above different nodes
            props.node !== draggedNode ||
            // Or hovered above the same node but at a different depth
            targetDepth !== props.path.length - 1;
            if (!needsRedraw) {
                return;
            }

            dragHover({
                node: draggedNode,
                path: item.path,
                minimumTreeIndex: listIndex,
                depth: targetDepth,
            });
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CATEGORY,
        options: { dropEffect: 'copy' },
        item: () => {
            startDrag(path);
            return { 
                node, 
                path,
                treeIndex,
             };
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    });

    function Collapsable() {
        if ( node.children &&
          (node.children.length > 0 || typeof node.children === 'function')) {
            return (
                <CollapseButton onClick={() =>
                    toggleChildrenVisibility({
                        path,
                        treeIndex,
                    })
                    }>
                    {node.expanded ? <RemoveRounded/> : <AddRounded />}
                </CollapseButton>
            )
        } 
  
        return null
    }


    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let block = 84;
    let hasChild = node.children &&
    (node.children.length > 0 || typeof node.children === 'function');
    return (
        
            <Grid container style={{ position: 'relative', paddingLeft: (depth * block) }}>
                
                <CollapseGrid item sx={hasChild ? {marginRight: '15px'} : { marginRight: 0 }}>
                    {Collapsable()}
                </CollapseGrid>
                <Grid item xs >
                    <NodePaper ref={ref}  elevation={0} style={{...style, opacity}} data-handler-_id={handlerId}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs>
                                {name}
                            </Grid>
                            <Grid item xs container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <ActionButton 
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        startIcon={<AddRounded />}
                                        onClick={() => {
                                            // dispatch(onSetParent(node._id))
                                                navigate(`/dashboard/categories/new/${node._id}`)
                                            }
                                        }>
                                        Subkategori
                                    </ActionButton>
                                </Grid>
                                <Grid item>
                                    <ActionButton 
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        onClick={() =>
                                            {
                                            // if (node.parent_id) {
                                            //     dispatch(onSetParent(node.parent_id))
                                            // }
                                                navigate(`/dashboard/categories/${node._id}/edit`)
                                            }
                                        }>
                                        Ubah
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </NodePaper>
                </Grid>
            </Grid>
    );
};


const CollapseButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    height: 69,
    width: 69,
    border: `1px solid #ddd`
}));

const ActionButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`
}));

const NodePaper = styled(Paper)(({theme}) => ({
    padding: '10px 15px',
    border: '1px solid #ddd',
    minHeight: 47,
    zIndex: 99,
    // position: 'relative',
    // '&:before': {
    //     position: 'absolute',
    //     content: '""',
    //     borderBottom: '2px dashed #ddd',
    //     height: 1,
    //     top: '48%',
    //     left: '-5%',
    //     width: '10%',
        
    // },
    // '&:after': {
    //     position: 'absolute',
    //     content: '""',
    //     borderLeft: '2px dashed #ddd',
    //     height: '100%',
    //     top: '50%',
    //     left: -52,
    //     width: 1,
    //     bottom: 0
        
    // },
}));

const CollapseGrid = styled(Grid)(({theme}) => ({
    // '&:before': {
    //     position: 'absolute',
    //     content: '""',
    //     borderBottom: '2px dashed #ddd',
    //     height: 1,
    //     top: '48%',
    //     left: 34,
    //     width: '10%',
        
    // },
    // '&:after': {
    //     position: 'absolute',
    //     content: '""',
    //     borderLeft: '2px dashed #ddd',
    //     height: '100%',
    //     top: '-50%',
    //     left: 34,
    //     width: 1,
    //     bottom: 0
        
    // },
}));
